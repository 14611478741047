﻿.faded-background {
    /*background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4fwYAAs0BzF1VEXcAAAAASUVORK5CYII=);*/
    background-color: rgba(255,255,255,0.41) !important;

}

/*needs to be worked on*/
.hero-consumer {
    background :url(/dist/images/hero-bg-home.jpg) no-repeat;
     background-position: center center;
    background-repeat: no-repeat;
    height: 459px;
    width:100%;
    
}
.hero-hcp {
    background :url(/dist/images/hero-bg-hcp02.jpg) no-repeat;
     background-position: center center;
    background-repeat: no-repeat;
    height: 459px;
    width:100%;
    
}
.textFormat{
    display:block !important;
    left:49px;
}
.hero-hcp p.lead-text, .hero-consumer p.lead-text {
    position: absolute;
    top: 12%;
    /*left: 242px;*/
    left: 20.5%;
    font-style: italic;
    color: #7f888e;
    /*text-indent: -14px;*/
    line-height:16px;
    font-size: 12.5px;
}

.hero-hcp .main-text, .hero-consumer .main-text {
    position: absolute;
    bottom: 28%;
    left: 20.5%;
}

.hero-hcp .main-text p, .hero-hcp .main-text h1, .hero-consumer .main-text p, .hero-consumer .main-text h1 {
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.hero-hcp h1, .hero-consumer h1 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    line-height: 21px !important;
    border-bottom: 0px;
    text-transform: none;
}

.hero-hcp h1 sup, .hero-consumer h1 sup {
    font-size: 8px;
    top: -0.8em;
}

.hero-hcp .main-text .orange-text, .hero-consumer .orange-text {
    color: #f48024;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .hero-hcp p.lead-text, .hero-consumer p.lead-text {
        left: 10%;
    }

}

.hero-container {
    /*height: 605px;*/
    margin: 45px auto 0 auto;
    max-width: 1260px;
    width: 100%;
}
.hero-container .row {
    position: relative;
}
.learnMore {
    margin-top:10px;
}

.headerSection h2{
    margin: 0;
    color: #fff;
    font-weight:bold;
    font-size: 20px;
}
.headerSection p {
   /* font-family: 'Asap', arial, sans-serif !important;*/
    margin-top:5px;
    font-size:13px;
    line-height:18px;
    color: #fff;
}
@media screen and (max-width: 767px) {
    .headerSection p {
        color: #000;
    }
}
div.last-active {
    opacity: 1;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    z-index: 1;
}
div.active {
    opacity: 1;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    z-index: 2;
}
.hero-image {
    background-position: center center;
    background-repeat: no-repeat;
    height: 605px;
    line-height: 1.25em;
    opacity: 0;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    width: 100%;
    z-index: 0;
}
.chaeli-image {
    @include webp-background-image("homepage-consumer-bg-chaeli", "jpg");
}
.chaeli-image-2 {
    @include webp-background-image("homepage-consumer-bg-chaeli-2", "jpg");
}
.bobbi-image {
    @include webp-background-image("homepage-consumer-bg-bobbi", "jpg");
}
.katie-image {
    @include webp-background-image("homepage-consumer-bg-katie", "jpg");
}
.tagline {
    background: rgba(0,0,0,.137) url(/dist/images/homepage-tagline.png) no-repeat center 30px;
    -moz-box-shadow: 0 0 50px rgba(0,0,0,.3);
    -webkit-box-shadow: 0 0 50px rgba(0,0,0,.3);
    box-shadow: 0 0 50px rgba(0,0,0,.3);
    color: #FFF;
    font-size: 13px;
    font-style: italic;
    font-weight: bold;
    line-height: 1.3em;
    opacity: 0;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    padding: 84px 30px 30px 30px;
    position: absolute;
    width: 379px;
    z-index: 10;
}

@media \0screen {  /* media hack for IE8 */
    .tagline {
        background: url(/dist/images/homepage-tagline.png) no-repeat center 30px;
    }
}
/*.hero-content {
    color: #414042;
    font-size: 13px;
    margin: 60px 0 0 0;
    padding: 15px 30px;
    z-index: 10;

    color: #ffffff;
    font-size: 13px;
     margin: 60px 0 0 0; 
    
    z-index: 10;
    position: absolute;
    bottom:-6px;
    width:100%;
    padding-left: 75px;
    padding-right: 151px;
      padding-top: 13px;
    padding-bottom: 15px;
}*/
.hero-content {
    z-index: 10;
        
    float: none;
    width:100%;
    margin-top: 0;
    padding: 15px 15px;
    
    background:none;
    color: #ffffff;
    font-size: 13px;
}
@media screen and (min-width: 1200px) {
    .hero-content {
        top: -86px;
        position: absolute;
        bottom:-6px;
    }
   /* .hero-container-hcp .hero-content {
        top: -136px;
        position: absolute;
        bottom:-6px;
    }*/
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content {   
        position: absolute;
        bottom:-6px;
    }
    .hero-container-hcp .hero-content {     
        position: absolute;
        bottom:-6px;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content {    
        position: absolute;
        bottom: 2.5rem;
    }
    .hero-container-hcp .hero-content {
    }
}
.hero-content .subheading {
    color: #ffffff;
    
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0 !important;
}
.hero-content .btn-lg {
    background: none repeat scroll 0 0 #f58026;
    color: #fff;
}
.hero-content .btn-lg:hover {
    background: #d54a0e;
}
.hero-callouts {
    margin-top: 10px;
    /*margin: 60px 0 0 0;*/
    /*margin: 20px 0 0 0;*/
    z-index: 10;
    /*padding-left:70px;*/
}
@media screen and (max-width: 767px) {
    .hero-callouts {
        margin-top: 25px;
    }
}
.hero-callouts a.callout {
    background-position: 15px 0;
    margin: 15px 0 0 0;
    padding: 0 15px 10px 88px;
}
#main-bar {
    z-index: 100;
}
#home-layout h1 {
	font-size: 30px;
	text-transform: none;
	width: auto;
    z-index: 4020
}
#home-content p {
	margin-bottom: 1em;
}
#learn-more-homepage {
	background: #F58026;
	color: #FFF;
	z-index: 4050;
}
#learn-more-homepage:hover {
    background: #d54a0e;
}
#home-caption p {
	background: #FFF;
	font-size: 12px;
	font-style: italic;
	font-weight: bold;
	line-height: 1.3em;
	margin: 0;
    z-index:4018;
}
#home-caption p.active {
    z-index:4020;
}
#home-caption p.last-active {
    z-index:4019;
}
#slideshow div {
    background: #FFF;
    opacity: 0;
    z-index:4008;
}
#slideshow div.active {
    opacity: 1;
    z-index:4010;
}
#slideshow div.last-active {
    z-index:4009;
}
#isi.tall {
    position: relative;
}

/* All desktop styles */
@media (min-width: 992px) {
   /* .new-background{
        padding-left:0;
    }*/
    #main-bar {
        border-bottom: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .main {
       // top: -20px;
       margin-bottom:24rem;
    }
	#home-layout {
		height: 424px;
		margin: 0 auto 0 auto;
		position: relative;
		width: 960px;
	}
	#home-layout h1 {
		background: none;
		left: 430px;
		position: absolute;
		top: 0;
		width: 480px;
		z-index: 4020;
	}
	#home-layout #home-content {
		left: 590px;
		line-height: 1.5em;
		position: absolute;
		top: 150px;
		width: 340px;
		z-index: 20;
	}
	.hcp #home-layout #home-content {
		left: 570px;
		top: 130px;
		width: 360px;
	}
	#home-layout #home-content .small {
		line-height: 1.4em;
	}
	#learn-more-homepage {
		left: 590px;
		position: absolute;
		top: 340px;
	}
    .hcp #learn-more-homepage {
        left: 570px;
    }
	#home-layout #home-caption {
		left: 10px;
		position: absolute;
		top: 30px;
		width: 210px;
		z-index: 4020;
	}
	#home-layout #home-caption p {
		left: 0;
		top: 30px;
		position: absolute;
	}
	#slideshow {
		bottom: 7px;
		height: 404px;
		left: 0;
		position: absolute;
		width: 495px;
	}
	#slideshow div {
        background: #FFF;
		bottom: 7px;
		left: 60px;
		position: absolute;
        z-index: 4000;
	}
    #slideshow IMG.active {
    }
	.home-bottom {
		bottom: 1px;
		position: absolute;
		width: 100%;
	}
	.callout {
		margin-top: 20px;
		padding-right: 40px;
	}
/*    #isi.tall .visible-xs {
        display: none !important;
    }
    #isi.tall .ISIlink.hidden-xs {
        display: block !important;
    }*/
}
/* End all desktop styles */

/* Large desktop styles */
@media (min-width: 1200px) {
     /*.new-background{
        padding-left:0;
        padding-right:0;
    }*/
    #tagline-1 {
        right: 60px;
        top: 235px;
    }
    #tagline-2 {
        left: 420px;
        top: 250px;
    }
    #tagline-3 {
        right: 60px;
        top: 250px;
    }
    #tagline-4 {
        right: 70px;
        top: 220px;
    }
    #tagline-5 {
        left: 420px;
        top: 250px;
    }
	.callout {
		min-height: 110px;
	}
	#home-layout {
		margin-left: 100px;
	}
}
/* End large desktop styles */

/* Small desktop styles */
@media (min-width: 992px) and (max-width: 1199px) {
    .hero-hcp p, .hero-consumer p, .hero-hcp h1, .hero-consumer h1 {
            top: 101px;
             left: 37px;
    }
    .hero-content{
        padding-right:21px;
    }
   
     /*.new-background{
        padding-left:0;
        padding-right:0;
    }*/
    #tagline-1 {
        right: 60px;
        top: 235px;
    }
    #tagline-2 {
        left: 490px;
        top: 250px;
    }
    #tagline-3 {
        right: 60px;
        top: 250px;
    }
    #tagline-4 {
        right: 70px;
        top: 220px;
    }
    #tagline-5 {
        left: 490px;
        top: 250px;
    }
    /*.hero-callouts {
        margin-top: 0;
    }*/
	.callout {
		min-height: 127px;
	}
}
/* End small desktop styles */

/* iPad styles */
@media (min-width:768px) and (max-width:768px) {
    .hero-hcp{
         background:url(/dist/images/hero-bg-ipad.png) no-repeat;
    }

   .hero-hcp p, .hero-consumer p, .hero-hcp h1, .hero-consumer h1 {
       top: 45px !important;
        left: 148px !important;
        line-height: 15px;
   }
  
   .learnMore{
       margin-top: 23px;

   }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hero-hcp{
        width: 100%;
            background: url(/dist/images/hero-bg-hcp02.jpg) no-repeat;
    background-size: cover; 
    
    }
    .hero-consumer {
        background-position: top 1.75rem center;
        background-size: 155%;
        height: auto;
        padding-bottom: 69.0159%;

        width: 100%;
        @include webp-background-image("hero-bg-home", "jpg");
        background-repeat: no-repeat;
       // background-size: cover;
    }
     .hero-content h2{
       margin-top:5px;
   }
    .hero-content{
      
    width: 100%;
    padding-left: 1px;
    padding-right: 0;
    padding-top:1px;
   
}
    .hero-hcp p, .hero-consumer p, .hero-hcp h1, .hero-consumer h1 {
      top: 104px;
    left: 44px;
    line-height: 15px;
    }
    
     .new-background{
        padding-left:0;
        padding-right:0;
    }
    /*.hero-content {
        margin-top: 30px;
    }*/
    /*.hero-callouts {
        margin-top: 28px;
        width:100%;
    }*/
    .hero-callouts a.callout {
        background-image: none;
        margin: 10px 0;
        padding: 0 15px 0 15px;
    }
    #tagline-1 {
        right: 0;
        top: 217px;
    }
    #tagline-2 {
        right: 0;
        top: 220px;
    }
    #tagline-3 {
        right: 0;
        top: 250px;
    }
    #tagline-4 {
        right: 0;
        top: 220px;
    }
    #tagline-5 {
        right: 0;
        top: 220px;
    }
	#home-layout {
		margin: 0 auto 20px auto;
        overflow: hidden;
		padding-bottom: 425px;
		position: relative;
	}
	#home-layout h1 {
		
	}
	#home-layout #home-content {
		line-height: 1.5em;
	}
	#learn-more-homepage {
		position: absolute;
		right: 0;
		top: 280px;
	}
	#home-layout #home-caption {
		width: 210px;
		z-index: 20;
	}
	#home-layout #home-caption p {
		left: 50px;
		bottom: 330px;
		position: absolute;
		width: 220px;
	}
	#slideshow {
		bottom: 7px;
		height: 505px;
		left: 65px;
		position: absolute;
		width: 619px;
	}
	#slideshow IMG {
		bottom: 7px;
		left: 50%;
		margin-left: -250px;
		position: absolute;
		width: 500px;
	}
	.home-bottom {
		bottom: 1px;
		position: absolute;
		width: 100%;
	}
    .home-bottom img {
	    max-width: 100%;
    }
	.callout {
		background: none;
		padding: 10px;
	}
}
/* end iPad styles */

/* Mobile styles */
@media screen and (max-width: 767px) {
    .hero-consumer {
        // background :url(/dist/images/hero-bg-home-mobile.jpg) no-repeat;
        background-position: top 1.15rem left 40%;
        background-size: cover;
        height: auto;
        padding-bottom: 69.0159%;
        width: 100%;
    }
    .hero-hcp {
    background :url(/dist/images/hero-bg-hcp02-mobile.jpg) no-repeat;
    background-position: center center;
   
    background-size: 100%;
    height: auto;
    padding-bottom: 69.0159%;
    width: 100%;
    
}
   .new-background{
       padding:0;
   }
   .headerSection{
       
       display:block;
   }
   .learnMore{
       margin-top: 25px;
   }
  
    .hero-content h2{
        color:#ea7232 !important;
        font-style:italic;
        margin-top: 15px;
    }
   .btm-callouts{
       clear: both;
       /*margin-top:355px !important;*/
   }
   .hero-hcp p.lead-text, .hero-consumer p.lead-text {
      
        position: absolute;
        top: 32px;
        left: 24px;
        font-style: italic;
        color: #7f888e;
        line-height: 13px;
        font-size: 10px;
   }
   .hero-hcp .main-text, .hero-consumer .main-text {
       bottom: 14%;
       left: 6.5%;
   }

   .hero-hcp .main-text p, .hero-consumer .main-text p, .hero-hcp .main-text h1, .hero-consumer .main-text h1 {
       font-size: 10px !important;
       font-weight: 500 !important;
       line-height: 16px !important;
       color: #fff !important;
   }
   .hero-hcp .main-text sup, .hero-consumer .main-text sup {
       top: -0.5em;
   }
    .faded-background {
        background-image: none;
    }
    .hero-container {
        height: auto;
        margin-top: 25px;
    }
    #hero-images {
        height: auto;
        padding-bottom: 48.01587%;
        position: relative;
    }
    .hero-image {
        background-size: cover;
        height: auto;
        padding-bottom: 48.01587%;
        width: 100%;
    }
    #taglines {
        background: #A0A0A0;
        height: 117px;
        padding: 15px;
        position: relative;
    }
    .tagline {
        background: transparent url(/dist/images/homepage-tagline.png) no-repeat center top;
        background-size: 270px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 49px 0 0 0;
        text-align: center;
        width: 90%;
        width: calc(100% - 30px);
    }
    .hero-callouts a.callout {
        background-image: none;
        margin: 15px 0 15px 0;
        padding: 0 15px 25px;
    }
	#home-layout {
		margin: 0 auto 20px auto;
	}
	#home-layout h1 {
		font-size: 24px;
		line-height: 1.25em;
	}
	#home-layout #home-content {
		line-height: 1.5em;
	}
	#home-layout #home-caption {
		height: 60px;
		margin-top: 1em;
		position: relative;
	}
	#home-layout #home-caption p {
		left: 50%;
		margin-left: -145px;
		position: absolute;
		text-align: center;
		top: 0;
		width: 290px;
	}
	#slideshow {
		height: 236px;
		position: relative;
	}
	#slideshow IMG {
		left: 50%;
		margin-left: -145px;
		position: absolute;
		top: 0;
		width: 290px;
	}
	.home-bottom {
		line-height: 0;
	}
    .home-bottom img {
	    max-width: 100%;
    }
	.callout {
		margin: 0 15px;
	}
}

@media screen and (min-width: 300px) and (max-width: 390px) {
    .hero-consumer {
        background: url(/dist/images/hero-bg-home-mobile.jpg) no-repeat;
        background-position: top 1.15rem left -.75rem;
        background-size: cover;
        height: auto;
        padding-bottom: 69.0159%;
        width: 100%;
    }
}
/* End Mobile styles */

